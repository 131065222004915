@import 'assets/scss/variables.module';

.container {
  background: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
  box-shadow: $generic-box-shadow;

  margin: 50px 0;
  padding: 2rem;

  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;

  @include response-medium-small {
    flex-wrap: wrap;

    .info,
    .bio {
      width: 100%;
    }
  }
}

.info {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

$avatar-size: 100px;

.avatar {
  height: $avatar-size;
  width: $avatar-size;
  border-radius: 50%;
  border: 1px solid $light-color2;
}

.bio {
  width: 75%;
}

.noBio {
  @include flex-center;

  font-weight: bold;
  color: $light-color2;
  height: 100%;
  text-align: center;
}

.user {
  @include flex-center;
  flex-direction: column;

  min-height: 150px;
  width: 100%;

  font-weight: bold;
  font-size: 16px;

  a {
    transition: color 0.2s ease;

    &:hover {
      color: $archidekt-orange;
    }
  }
}

.hr {
  width: 100%;
  border: 1px solid $light-color2;
}

.noShowOnDesktop {
  display: none;

  @include response-medium-small {
    display: unset;
  }
}

.extras {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-top: 1rem;
}
