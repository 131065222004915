@import 'assets/scss/variables.module';

$icon-size: 45px;
$icon-size-small: 30px;

.container {
  i {
    display: block;
    margin-bottom: 1rem;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid $light-color2;

  a {
    font-size: 16px;
    font-weight: bold;

    display: flex;
    align-items: center;
    gap: 10px;

    transition: color 0.2s ease;

    &:hover {
      color: $archidekt-orange;
    }
  }

  div {
    @include response-small {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @include response-x-small {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  img {
    height: $icon-size;
    width: $icon-size;

    @include response-small {
      height: $icon-size-small;
      width: $icon-size-small;
    }
  }
}

.followButton {
  width: 135px;
}

.users {
  > :last-child {
    border-bottom: none;
  }
}

.loadMore {
  width: 100%;
  display: flex;
  justify-content: center;

  margin: 1rem 0;

  button {
    background: transparent;
    color: $color;
    border: none;
    font-weight: bold;
    transition: color 0.2s ease;

    &:hover {
      cursor: pointer;
      text-decoration: underline;

      color: $light-color;
    }
  }
}
