@import 'assets/scss/variables.module';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 100px;
}

.allDecksLink {
  font-weight: bold;
  font-size: 20px;
  margin: 2rem 0;
}

.containWidth {
  width: 1725px;
  max-width: 90%;
  margin-bottom: 50px;

  @include response-x-large {
    width: 1345px;
  }

  @include response-large {
    width: 1025px;
  }

  @include response-medium {
    width: 90%;
  }
}

.noDecks {
  @include flex-center;

  background: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
  box-shadow: $generic-box-shadow;
  height: 100px;

  font-weight: bold;
  color: $light-color2;

  text-align: center;
}
