@import 'assets/scss/variables.module';

$size: 75px;

.container {
  width: 1725px;
  max-width: 90%;

  @include response-x-large {
    width: 1345px;
  }

  @include response-large {
    width: 1025px;
  }

  @include response-medium {
    width: 90%;
  }
}

.users {
  display: grid;
  justify-items: center;
  grid-row-gap: 0.75rem;

  & > * {
    width: 100%;
    min-width: 125px;

    display: none; // Hide all by default then show them based on how many items you want to show vs the max number of rows you want
  }

  grid-template-columns: repeat(10, 1fr);

  @include response-min-x-large {
    & > *:nth-child(-n + 10) {
      display: unset;
    }
  }

  @include response-large-to-x-large {
    grid-template-columns: repeat(8, 1fr);

    & > *:nth-child(-n + 8) {
      display: unset;
    }
  }

  @include response-medium-to-large {
    grid-template-columns: repeat(6, 1fr);

    & > *:nth-child(-n + 6) {
      display: unset;
    }
  }

  @include response-small-to-medium {
    grid-template-columns: repeat(4, 1fr);

    & > *:nth-child(-n + 8) {
      display: unset;
    }
  }

  @include response-x-small-to-small {
    grid-template-columns: repeat(3, 1fr);

    & > *:nth-child(-n + 9) {
      display: unset;
    }
  }

  @include response-x-small {
    grid-template-columns: repeat(2, 1fr);

    & > *:nth-child(-n + 6) {
      display: unset;
    }
  }
}

.link {
  transition: color 0.2s ease;

  & > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: bold;
  }

  &:hover {
    color: $archidekt-orange;

    img {
      transform: scale(1.1);
    }
  }
}

.imageContainer {
  height: $size;
  width: $size;

  border-radius: 50%;
  border: 1px solid $light-color2;

  overflow: hidden;

  img {
    height: 100%;
    width: 100%;

    transition: transform 0.5s ease;
    border: none;
    border-radius: 0;
  }
}
.username {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.noFollowers {
  @include flex-center;

  background: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
  box-shadow: $generic-box-shadow;
  height: 100px;

  font-weight: bold;
  color: $light-color2;

  text-align: center;
}

.loading {
  animation: breathing 1.25s ease-out infinite normal;
  pointer-events: none;
}

@keyframes breathing {
  0% {
    background: $light-background;
  }

  50% {
    background: $light-background2;
  }

  100% {
    background: $light-background;
  }
}
