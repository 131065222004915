@import 'assets/scss/variables.module.scss';

$border-radius: 5px;

.folderItem {
  padding: 15px 25px;
  box-shadow: $generic-box-shadow;
  transition: opacity 0.2s ease;
  border-radius: $border-radius;
  border: 1px solid $light-color2;

  font-size: 14px;

  transition: color 0.2s ease, background-color 0.2s ease;

  &:hover {
    color: $archidekt-orange;
    background-color: $light-background2;
  }
}

.noFolders {
  @include flex-center;

  background: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
  box-shadow: $generic-box-shadow;
  height: 100px;

  font-weight: bold;
  color: $light-color2;

  text-align: center;
}

.loading {
  animation: breathing 1.25s ease-out infinite normal;
  pointer-events: none;
}

@keyframes breathing {
  0% {
    background: $light-background;
  }

  50% {
    background: $light-background2;
  }

  100% {
    background: $light-background;
  }
}
